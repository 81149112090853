export default {
    name: 'ArchiveCount',
    data() {
        return {
            archives_all:0,
            archives_add:0,
            items: [{time: '2021'}],
            date:null,
            columns: [
                {
                    title: '日期',
                    key: 'date',
                    align:'center'
                },
                {
                    title: '新增档案',
                    key: 'add_total',
                    align:'center'
                },
                {
                    title: '商务协议档案数',
                    key: 'add_business_contract_total',
                    align:'center'
                },
                {
                    title: '内部员工档案数',
                    key: 'add_internal_staff_total',
                    align:'center'
                },
                {
                    title: '外派员工档案数',
                    key: 'add_external_staff_total',
                    align:'center'
                },
                {
                    title: '其他档案数',
                    key: 'add_other_total',
                    align:'center'
                },
                {
                    title: '档案总数',
                    key: 'total',
                    align:'center'
                },
            ],
            total: 0,
            total_page: 16,
            page: 1,
            options: {
                disabledDate (date) {
                    return date && date.valueOf() > Date.now() - 86400000;
                }
            },
            begin_date:null,
            end_date:null,
        }
    },
    mounted() {
        this.getEverydayArchivesAdd();
        this.getSata();
    },


    methods: {
        archives_detail(){
            this.$router.push({path:'/count'});
        },
        changePage(pa) {
            this.page=pa;
            this.getEverydayArchivesAdd();
        },
        changeDate(da){
            this.begin_date=da[0];
            this.end_date=da[1];
            this.getEverydayArchivesAdd();
        },
        // 获取每天的档案新增详情列表
        getEverydayArchivesAdd(){
            this.$api.archives.everydayArchivesAdd({
                page:this.page,
                page_number:this.total_page,
                begin_date:this.begin_date,
                end_date:this.end_date,

            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.items=res.list;
                this.total=res.obj.total;
                // this.birthday_total=res.obj.total;
                // this.birthday_page=res.obj.page;
                // this.birthday_total=res.obj.total_page;
                // this.staff=res.list;
            }).catch(err => {
                console.log(err)
            })
        },
        exportData (type) {
                this.$refs.table.exportCsv({
                    filename: '档案新增详情报表'
                })
        } ,

        getSata(){
            this.$api.archives.stat().then(res => {
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.archives_add=res.obj.add_num;
                this.archives_all=res.obj.total_num;
            }).catch(err => {
                console.log(err)
            })
        },
    }
}